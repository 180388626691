<template>
    <EmptyStateError :data-error="bootError" :error="t('errors.general')" class="boot-load-error" />
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from '@/stores/global';
import EmptyStateError from '@/components/EmptyStateError.vue';

export default {
    components: {
        EmptyStateError,
    },

    setup() {
        const globalStore = useGlobalStore();
        const bootError = computed(() => globalStore.boot.error);

        return {
            t: useI18n().t,
            bootError,
        };
    },
};
</script>

<style lang="scss" scoped>
.boot-load-error {
    padding: $spacing-400;
    text-align: center;
}
</style>
