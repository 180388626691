<template>
    <EmptyState :title-text="error" image="oops">
        <i18n-t keypath="errors.loginQuestion" scope="global">
            <RouterLink to="/logout?renew=true" class="login-link">
                {{ t('errors.loggingIn') }}
            </RouterLink>
        </i18n-t>
    </EmptyState>
</template>

<script>
import { useI18n } from 'vue-i18n';
import EmptyState from '@/components/EmptyState.vue';

export default {
    components: {
        EmptyState,
    },

    props: {
        error: {
            type: String,
            default: '',
        },
    },

    setup() {
        return { t: useI18n().t };
    },
};
</script>
