<template>
    <DsLogo class="logo" />

    <div class="unauthorized-page">
        <h1 class="title">
            {{ t('unauthorized.title') }}<br />
            {{ t('unauthorized.title2') }}
        </h1>

        <DsLink
            data-qa="logout-link"
            @click="logout"
        >
            {{ t('button.logout') }}
        </DsLink>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useAccountsStore } from '@/accounts/store';

export default {
    setup() {
        const accountsStore = useAccountsStore();

        return {
            t: useI18n().t,
            accountsStore,
        };
    },

    methods: {
        async logout() {
            this.accountsStore.updateAppId('');
            this.$router.push('/logout');
        },
    },
};
</script>

<style lang="scss" scoped>
.unauthorized-page {
    @include container($spacing-200, $spacing-200);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: $font-size-md;
}

h1 {
    margin-bottom: $spacing-400;
}

.logo {
    position: absolute;
    @include position-start(0);
    margin: $spacing-400;
    width: px-to-rem(100);
}

@media($large) {
    h1 {
        font-size: $font-size-mega;
    }

    .unauthorized-page {
        max-width: px-to-rem(1000);
    }
}
</style>
